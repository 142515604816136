@import url(https://fonts.googleapis.com/css2?family=Almarai:wght@400;700;800&display=swap);
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Almarai", sans-serif;
  color: #f5f5ef;
}
.App {
  height: 100vh;
  width: 100vw;
  background-color: #f5f5ef;
  direction: rtl;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home{display:flex;justify-content:center;align-items:center;flex-direction:column;width:-webkit-max-content;width:-moz-max-content;width:max-content;color:#f5f5ef}.Home .order{background:#b69582;border:0;padding:1em;width:100%;border-radius:0.5em;font-size:1.7em;font-weight:bold;text-decoration:none;text-align:center}.Home .logo{width:10em}.Home .year{width:100%}

.MenuItem{padding:1em;display:flex;justify-content:space-around;width:100vw}.MenuItem .preview{-webkit-filter:drop-shadow(2px 4px 8px #585858);filter:drop-shadow(2px 4px 8px #585858)}.MenuItem .info{display:flex;flex-direction:column}

