.Home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: max-content;
  color: #f5f5ef;
  .order {
    background: #b69582;
    border: 0;
    padding: 1em;
    width: 100%;
    border-radius: 0.5em;
    font-size: 1.7em;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
  }

  .logo {
    width: 10em;
  }
  .year {
    width: 100%;
  }
}
