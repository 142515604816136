@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@400;700;800&display=swap");

* {
  font-family: "Almarai", sans-serif;
  color: #f5f5ef;
}
.App {
  height: 100vh;
  width: 100vw;
  background-color: #f5f5ef;
  direction: rtl;
  display: flex;
  justify-content: center;
  align-items: center;
}
